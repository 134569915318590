import React, {  useState, useEffect } from "react";
import { Rates } from "./Rates";

function sloterizeDate() {
  const minutePrecision = 30;
  const newRateDateSlot = new Date();
  let newMinutes =
    Math.floor(newRateDateSlot.getMinutes() / minutePrecision) *
    minutePrecision;
  newRateDateSlot.setMinutes(newMinutes);

  newRateDateSlot.setMilliseconds(0);
  newRateDateSlot.setSeconds(0);
  return newRateDateSlot;
}

export const RatesPage = () => {
  const [rateDateSlot, setDate] = useState(sloterizeDate());


  useEffect( () => {
    function compareDates() {
      let newDateSlot = sloterizeDate();
      if (
        
        rateDateSlot.getTime() !== newDateSlot.getTime()
      ) {
        
        setDate(newDateSlot);
        console.log(newDateSlot);
      }
  
    }
    const interval = setInterval(compareDates, 1000);

    return () => clearInterval(interval);
  }, [rateDateSlot])
   
 
  return (
  <>
  <Rates tariff="E-1R-AGILE-FLEX-22-11-25-M" rateDate={rateDateSlot} className="col-12" />
  </>
  )
  
};

