import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function filterToDayOfMonth(slots, dayOfMonth) {
  const filterfn = (x) => {
    return new Date(x.valid_from).getDate() === dayOfMonth;
  };
  //results = results.filter(x => (new Date(x.valid_from)).getDate() === dayOfMonth)
  slots = slots.filter(filterfn);

  return slots;
}

export function RateSlotsSet({ data, rateDate }) {
  if (data && data.length > 0) {
    let slots = filterToDayOfMonth(data, rateDate.getDate());
    slots.map(
      (slot) =>
        (slot.value_inc_vat_2dp = Math.round(slot.value_inc_vat * 100) / 100)
    );

    let slice1 = slots.slice(0, 12);
    let slice2 = slots.slice(12, 24);
    let slice3 = slots.slice(24, 36);
    let slice4 = slots.slice(36);
    return (
      <>
        <Container className=" ">
          <Row className="col-12 slotset col-sm-6 col-md-4 col-lg-3 ">
            {<RateSlots data={slice1} rateDate={rateDate} />}
          </Row>
          <Row className="col-12 slotset col-sm-6 col-md-4 col-lg-3">
            {<RateSlots data={slice2} rateDate={rateDate} />}
          </Row>
          <Row className="col-12 slotset col-sm-6 col-md-4 col-lg-3">
            {<RateSlots data={slice3} rateDate={rateDate} />}
          </Row>
          <Row className="col-12 slotset col-sm-6 col-md-4 col-lg-3  ">
            {<RateSlots data={slice4} rateDate={rateDate} className=" "/>}
          </Row>
        </Container>
      </>
    );
  } else {
    return <div>....Loading</div>;
  }
}

export function RateSlots({ data, rateDate }) {
  if (data && data.length > 0) {
    let slots = filterToDayOfMonth(data, rateDate.getDate());
    slots.map(
      (slot) =>
        (slot.value_inc_vat_2dp = Math.round(slot.value_inc_vat * 100) / 100)
    );
    return (
      <>
        {slots.map((slot, key) => (
          <RateSlot slot={slot} key={key} rateDate={rateDate} className=" " />
        ))}
      </>
    );
  } else {
    return <div>....Loading</div>;
  }
}

export function RateSlot({ slot }) {
  let options = {
    hour12: false,
  };

  let datestage1 = new Date(slot.valid_from);
  let datestage2 = datestage1.toLocaleTimeString("en-us", options);
  let time1string = datestage2.substring(0, 5);

  let currentSlotClass = slot.isCurrentSlot ? "bg-primary" : "";

  return (
    <>
      <Col className={` font-monospace  ${currentSlotClass} className="col-12"`}>
            {time1string} <span title={slot.value_inc_vat} >{slot.value_inc_vat_2dp.toFixed(2)}</span>
      </Col>
    </>
  );
}
