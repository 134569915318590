import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Container } from "react-bootstrap";
import { RatesPage } from "./RatesPage";

//import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Container className="col-12">
     
        This site has no affiliation with Octopus Energy &nbsp;
        <a href="https://share.octopus.energy/frost-may-501">
          https://share.octopus.energy/frost-may-501
        </a>
      
    </Container>
    <RatesPage className="col-12"/>
    <Container>
      <div>
        This site has no affiliation with Octopus Energy &nbsp;
        <a href="https://share.octopus.energy/frost-may-501">
          https://share.octopus.energy/frost-may-501
        </a>
      </div>
    </Container>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
