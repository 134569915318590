import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { RateSlotsSet } from "./RateSlots";


export const Rates = ({ tariff, rateDate }) => {
  // first run
  // Get todays date ie 25 April 2024-04-25
  //
  // Look in local stash for 2024-04-25-Full
  // Not there?
  // Look in local stach for 2024-04-25-Part
  // Not there?
  // Hit api for period today into tomorrow
  // what came back,
  //   - just today, store as -Part
  //   - today and tomorrow? stora as today full, tomorrow Part

  const [data, setData] = useState({});

  const annotateCurrentSlot = (data) => {
    data.map((x) => {
      const nowDate = new Date();
      x.isCurrentSlot =
        new Date(x.valid_from) <= nowDate && new Date(x.valid_to) >= nowDate;
      if (x.isCurrentSlot) {
        console.log();
      }
      return x;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      //const today = new Date();
      const today = rateDate;
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      const dateKeyToday = tariff + "-" + dateFormat(today, "yyyy-mm-dd");
      const dateKeyTommorow = tariff + "-" + dateFormat(tomorrow, "yyyy-mm-dd");

      let todayObj = localStorage.getItem(dateKeyToday);
      let tomorrowObj = localStorage.getItem(dateKeyTommorow);

      if (tomorrowObj) {
        let newData = JSON.parse(tomorrowObj);
        //console.log(data.count)

        annotateCurrentSlot(newData);
        setData(newData);
      } else if (todayObj) {
        //let data = JSON.parse(todayPartObj);
        //console.log(data.count)
        let newData = JSON.parse(todayObj);
        annotateCurrentSlot(newData);
        setData(newData);
      } else {
        const url = `https://api.octopus.energy/v1/products/AGILE-FLEX-22-11-25/electricity-tariffs/${tariff}/standard-unit-rates/?page_size=96`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        let responseJsonObj = await response.json();
        let newData = responseJsonObj?.results;

        //let latestData = JSON.parse(todayPartObj);
        //latestDate could be today at 10pm
        // or tomorrow at 10pm

        newData = newData.sort((a, b) =>
          a.valid_from > b.valid_from ? 1 : b.valid_from > a.valid_from ? -1 : 0
        );

        //

        let postFilteredResults = JSON.stringify(newData);
        localStorage.setItem(dateKeyToday, postFilteredResults);
        annotateCurrentSlot(newData);
        setData(newData);
      }
    };

    fetchData();
  }, [rateDate, tariff]);

  if (data && data.constructor.name === "Array") {
    return (

            <RateSlotsSet data={data} rateDate={rateDate} className="col-12"/>
    );
  } else {
    return <div>....Loading</div>;
  }
};
